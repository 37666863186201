<template>
  <div>
    <div class="text-content">
      <p class="text-contact">
        Caso surja alguma dúvida você pode entrar em contato as opções de atendimento abaixo:
      </p>
    </div>
    <v-container class="contact-boxes">
      <v-row>
        <v-col class="telephone-box">
          <p class="text-telephone" v-if="$vuetify.breakpoint.mdAndUp">Telefone</p>
          <v-card class="mx-auto" max-width="344" outlined>
            <div class="mobile-phone" v-if="$vuetify.breakpoint.smAndDown">
              <p class="text-telephone">Telefone</p>
              <v-icon class="icon-phone">mdi-phone</v-icon>
            </div>
            <v-icon class="icon-phone" v-if="$vuetify.breakpoint.mdAndUp">mdi-phone</v-icon>
            <a href="tel:1133333333">11 4935 2321</a>
          </v-card>
        </v-col>
        <v-col>
          <p class="text-email" v-if="$vuetify.breakpoint.mdAndUp">E-mail</p>
          <v-card class="mx-auto" max-width="344" outlined>
            <div class="mobile-phone" v-if="$vuetify.breakpoint.smAndDown">
              <p class="text-telephone">E-mail</p>
              <v-icon class="icon-email">mdi-email</v-icon>
            </div>
            <v-icon class="icon-email" v-if="$vuetify.breakpoint.mdAndUp">mdi-email</v-icon>
            <a href="mailto:faleconosco@finago.com.br" title="">meajuda@finago.com.br</a>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-title">
      <p>Manual Me Ajuda Finago</p>
    </div>
    <div class="text-content">
      <p class="text-help">
        Faça o download do Manual com todas as informações necessárias para guiar você na sua
        jornada Finago!
      </p>
    </div>
    <v-col md="3" offset-md="12" class="colSolicitation">
      <button class="btnNewRequest" @click="handleClick">Baixar Manual</button>
    </v-col>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Contact",

  computed: {
    ...mapGetters({
      registration: "Registration/getConsultRegistration"
    })
  },
  methods: {
    ...mapActions({
      setConsultRegistration: "Registration/setConsultRegistration"
    }),
    forceFileDownload() {
      const link = document.createElement("a");
      if (this.registration.profile[0] === "seller") {
        link.href = "/attachments/manual.pdf";
        link.setAttribute("download", "manual.pdf");
      } else {
        link.href = "/attachments/manual-fornecedor.pdf";
        link.setAttribute("download", "manual-fornecedor.pdf");
      }
      document.body.appendChild(link);
      link.click();
    },

    handleClick() {
      this.forceFileDownload();
    }
  },
  async created() {
    await this.setConsultRegistration();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/commons/contact.scss";
</style>
